<template>
  <div class='scroll-gesture-mobile' />
</template>

<style scoped lang="scss">
.scroll-gesture-mobile {
  width: 3.5rem;
  aspect-ratio: 1;
  border-radius: 100%;
  background-color: var(--white);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(/assets/images/scroll-gesture/scroll-gesture-mobile.gif);
  transition: opacity 0.5s;

  &.is_gyro {
    background-image: url(/assets/images/scroll-gesture/scroll-gesture-mobile-gyro.gif);
  }

  &.is_scrolled {
    opacity: 0;
  }
}
</style>
