<script setup lang="ts"></script>

<template>
  <svg viewBox='0 0 24 38' class='scroll-gesture'>
    <rect
      x='1'
      y='1'
      width='22'
      height='36'
      rx='11'
      stroke='white'
      stroke-width='2'
    />
    <rect
      class='scroll-gesture__bar'
      x='10'
      y='7'
      width='4'
      height='10'
      rx='2'
      fill='white'
    />
  </svg>
</template>

<style lang="scss" scoped>
.scroll-gesture {
  position: absolute;
  width: 1.5rem;
  height: 2.375rem;
  fill: none;
  animation: scrollGestureAnimation 8s 2s infinite;

  @media (min-width: $breakpoint-xxl) {
    width: 3rem;
    height: 4.75rem;
  }
}

.scroll-gesture__bar {
  animation: scrollGestureBarAnimation 3s 5s infinite;
}

@keyframes scrollGestureAnimation {
  0%,
  40%,
  100% {
    transform: translate(0, 0) rotate(0deg);
  }
  5%,
  25% {
    transform: translate(50%, 0%) rotate(5deg);
  }
  15%,
  35% {
    transform: translate(-50%, 0%) rotate(-5deg);
  }
}

@keyframes scrollGestureBarAnimation {
  0%,
  50%,
  100% {
    transform: translateY(0%) scaleY(1);
    animation-timing-function: linear;
  }
  70% {
    opacity: 1;
    transform: translateY(2px) scaleY(1.2);
    animation-timing-function: linear;
  }
  80% {
    transform: translateY(22px) scaleY(0.4);
    opacity: 0;
    animation-timing-function: linear;
  }
  90% {
    opacity: 0;
    transform: translateY(0) scaleY(0.4);
  }
}
</style>
