<script setup lang="ts">
import { useApi as ShopsAPI } from '~/shared/api/shops';
import { useApi as FaqAPI } from '~/shared/api/faq';
import { useMainStore } from '~/store/main/main';

import MainHero from '~/components/Main/HeroCat/MainHero.vue';
import Banner from '~/components/Main/Banner.vue';
import Faq from '~/components/Main/Faq.vue';
import Shops from '~/components/Main/Shops.vue';
import { useRoute } from '#vue-router';

const store = useMainStore();
const shopsNumOnMainPage: number = 6;
const { FAQList, shops } = storeToRefs(store);

await useAsyncData(async () => {
  const { getShops } = ShopsAPI();
  const { getFAQ } = FaqAPI();

  const shops = await getShops();
  const faq = await getFAQ();

  if (shops.success) store.setShops(shops.data);
  if (faq.success) store.setFaq(faq.data);

  if (!shops.success) console.error('Failed to set shops data');
  if (!faq.success) console.error('Failed to set faq data');

  return shops.success && faq.success;
});

useHead({
  link: [{ rel: 'canonical', href: 'https://www.kitekat.ru' }],
});
</script>

<template>
  <div class='page-index'>
    <MainHero class='page-index__hero' />
    <Banner class='page-index__diets' />
    <Faq :faq-list='FAQList.slice(0, shopsNumOnMainPage)' />
    <Shops :shops='shops' />
  </div>
</template>

<style scoped lang="scss">
</style>
