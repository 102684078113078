<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { usePageStore } from '@/store/page/page';
import { useMainStore } from '~/store/main/main';
import { getWindowScrollTop } from '@/shared/utils/getWindowScrollTop';

import Container from '~/components/UI/Container.vue';

import MainCat from '~/components/Main/HeroCat/MainCat.vue';
import ScrollGesture from '~/components/Main/HeroCat/ScrollGesture.vue';
import ScrollGestureMobile from '~/components/Main/HeroCat/ScrollGestureMobile.vue';
import TapGesture from '~/components/Main/HeroCat/TapGesture.vue';
import Glow from '~/components/Main/HeroCat/Glow.vue';

const windowHeight = ref<number>(0);
const isCatHidden = ref<boolean>(true);
const isTapGestures = ref<boolean>(false);
const partOne = ref<boolean>(false);
const partTwo = ref<boolean>(false);

const calendarVideo = useTemplateRef('calendar-video');

const pageStore = usePageStore();
const mainStore = useMainStore();

const { animationPlayHero } = storeToRefs(mainStore);
const { isMobile } = storeToRefs(pageStore);

const onScroll = () => {
  isCatHidden.value = getWindowScrollTop() > windowHeight.value;
};

const onResize = () => {
  windowHeight.value = window.innerHeight / 1.5;
};

const animationPlay = () => {
  const video = calendarVideo;

  if (video && animationPlayHero) {
    setTimeout(() => {
      partOne.value = true;
      if (video.value) {
        video.value.play();
      }
      setTimeout(() => {
        if (video.value) {
          video.value.pause();
        }
      }, 3000);
      setTimeout(() => {
        partTwo.value = true;
      }, 4000);
      setTimeout(() => {
        if (calendarVideo.value) {
          calendarVideo.value.currentTime = 0;
        }
        partOne.value = false;
        partTwo.value = false;
      }, 5000);
    }, 100);
  }
};

const goToNextPage = () => {
  const element = document.querySelector('.page-index__diets');
  if (!!element) {
    const offset = element.getBoundingClientRect().top - 60;
    window.scrollTo(0, offset);
  }
};

const showTaps = () => {
  isTapGestures.value = true;
};

onMounted(() => {
  onResize();
  onScroll();
  window.addEventListener('scroll', onScroll);
  window.addEventListener('resize', onResize);
  setTimeout(() => animationPlay(), 3000);
});

onUnmounted(() => {
  window.removeEventListener('scroll', onScroll);
  window.removeEventListener('resize', onResize);
});
</script>

<template>
  <section
    id='hero'
    class='hero'
    :class='{
      animated_first: partOne,
      animated_second: partTwo,
    }'
  >
    <Container class='hero__container'>
      <h1 class='hero__title'>
        В чём секрет энергии кота Бориса?
      </h1>
    </Container>

    <Container
      class='hero__cat-container'
      :class='{ is_hidden: isCatHidden }'
    >
      <Glow class='hero__glow' />

      <div class='hero__cat'>
        <MainCat @autoplay-ends='showTaps' />
      </div>

      <div class='hero__move move'>
        <div class='move__bracket move__bracket--left'>
          <img
            src='~/assets/images/main-cat-badges/bracket-left.webp'
            alt='Стрелка'
            loading='lazy'
          >
        </div>
        <div class='move__bracket move__bracket--right'>
          <img
            src='~/assets/images/main-cat-badges/bracket-right.webp'
            alt='Стрелка'
          />
        </div>
        <div class='move__hearth'>
          <img
            src='~/assets/images/main-cat-badges/heart-bubble.webp'
            alt='Сердце'
          />
        </div>
        <div class='move__calendar'>
          <video ref='calendar-video' muted playsinline>
            <source src='~/assets/video/calendar.mp4' type='video/mp4' />
            <source src='~/assets/video/calendar.mov' type='video/mov' />
          </video>
        </div>
        <div class='move__line'>
          <div class='move__line-bg'></div>
          <div class='move__line-inner'></div>
        </div>
      </div>
    </Container>

    <ScrollGesture class='hero__scroll-gesture' />
    <ScrollGestureMobile
      class='hero__scroll-gesture-mobile'
      @click='goToNextPage'
    />

    <TapGesture
      v-if='isMobile && isTapGestures'
      :delay='1'
      class='hero__tap-gesture hero__tap-gesture--1'
    />
    <TapGesture
      v-if='isMobile && isTapGestures'
      :delay='0'
      class='hero__tap-gesture hero__tap-gesture--2'
    />
    <TapGesture
      v-if='isMobile && isTapGestures'
      :delay='2'
      class='hero__tap-gesture hero__tap-gesture--3'
    />
  </section>
</template>

<style lang="scss" scoped>
.hero {
  min-height: calc(100vh - 6.25rem);
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: $breakpoint-xxl) {
    min-height: calc(100vh - 4.875rem);
  }
}

.hero__cat-container {
  padding-left: 0;
  padding-right: 0;

  @media (min-width: $breakpoint-lg) and (orientation: landscape) {
    position: fixed;
    inset: 0;
    overflow: hidden;
    display: flex;
    align-items: flex-end;
  }

  &.is_hidden {
    display: none;
  }
}

.hero__glow {
  position: absolute;
  left: 50%;
  bottom: 0;
  transition: opacity 1s ease;
  transform: translateX(-50%);
  width: 100%;
  aspect-ratio: 1;

  @media (orientation: landscape) {
    width: 100vh;
    aspect-ratio: 8/5;
    top: auto;
    bottom: 0;
  }

  @include ifparent(".is_up") {
    opacity: 0;
  }
}

.hero__cat {
  width: min(130vw, 600px);
  position: relative;
  left: 50%;
  transform: translateX(-50%);

  @media (min-width: $breakpoint-lg) and (orientation: landscape) {
    width: fit-content;
  }
}

.hero__title {
  font-family: var(--font-dom);
  font-size: 2.75rem;
  font-weight: normal;
  line-height: 1;
  text-wrap: balance;
  text-align: center;
  margin-top: 0;
  margin-bottom: 3rem;

  @media (max-width: ($breakpoint-xl - 1)) or (orientation: portrait) {
    margin-bottom: 2rem;
  }

  @media (min-width: $breakpoint-lg) {
    font-size: 3.5rem;
  }
}

.hero__scroll-gesture {
  position: absolute;
  right: 4rem;
  top: 50%;
  transform: translateY(-50%);

  @media (max-width: ($breakpoint-lg - 1)) {
    display: none;
  }
}

.hero__scroll-gesture-mobile {
  position: absolute;
  bottom: 5rem;
  right: 1rem;
  z-index: 2;

  @media (min-width: $breakpoint-lg) {
    display: none;
  }
}

.hero__tap-gesture {
  position: absolute;

  &--1 {
    left: -2rem;
    top: 45%;
  }

  &--2 {
    left: 40%;
    top: 27%;
  }

  &--3 {
    right: -1.5rem;
    bottom: 24%;
  }
}

$parentW: 2137px;
$parentH: 1080px;
$parentMobW: 375px;
$parentMobH: 667px;
$moveW: 425px;
$moveH: 490px;

.move {
  position: absolute;

  width: calc(130px / $parentMobW * 100%);
  padding-bottom: calc(140px / $parentMobW * 100%);
  top: 50%;
  left: 53%;
  transform: translateX(-50%);
  z-index: 1;
  opacity: 0;

  @media (min-width: $breakpoint-lg) and (orientation: landscape) {
    width: calc($moveW / $parentW * 100%);
    padding-bottom: calc($moveH / $parentW * 100%);
    top: 110%;
    left: calc(225px / $parentW * 100%);
    z-index: 3;
    transform: none;
    opacity: 1;
  }
}

.move__bracket {
  position: absolute;
  width: calc(20px / $moveW * 100%);
  top: 0;
  transform: translateY(-32%);
  z-index: 2;

  &--left {
    left: calc(96px / $moveW * 100%);
  }

  &--right {
    right: calc(116px / $moveW * 100%);
  }

  img {
    width: 100%;
    height: auto;
  }
}

.move__hearth {
  position: absolute;
  top: calc(120px / $moveH * 100%);
  right: calc(40px / $moveW * 100%);
  width: calc(90px / $moveW * 100%);
  transform: rotate(-5deg) scale(0);
  z-index: 2;

  img {
    width: 100%;
    height: auto;
  }
}

.move__calendar {
  position: absolute;
  top: 0;
  left: calc(44px / $moveW * 100%);
  width: calc(323px / $moveW * 100%);
  padding-bottom: calc(321px / $moveW * 100%);
  border-radius: calc(50 / 323 * 100%);
  overflow: hidden;
  box-shadow: 0 6px 45px #262c24;
  background-image: url('/assets/images/main-cat-badges/calendar-bg.webp');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  video {
    position: absolute;
    display: block;
    width: 101%;
    top: 0;
    left: -1px;
    z-index: 1;
    background: transparent;
    border-radius: 55px;
  }
}

.move__line {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: hidden;
  padding-top: calc(136px / $moveW * 100%);
}

.move__line-bg {
  position: absolute;
  top: 0;
  left: 100%;
  right: 0;
  height: 100%;
  background-image: url('/assets/images/main-cat-badges/paint_2x.webp');
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: right center;
}

.move__line-inner {
  position: absolute;
  width: 58%;
  height: 65%;
  top: 3%;
  left: 22%;
  max-width: 0;
  background-image: url('/assets/images/main-cat-badges/in_Russia.webp');
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: left;
}

.animated_first {
  .move {
    animation-timing-function: ease-in-out;
    animation-duration: 1s;

    @media (min-width: $breakpoint-lg) and (orientation: landscape) {
      animation-name: calendar-01;
      top: calc(442px / $parentH * 100%);
    }

    @media not ((min-width: $breakpoint-lg) and (orientation: landscape)) {
      opacity: 1;
      transition: opacity 0.25s;
      transition-delay: 0.5s;
      animation-name: calendar-mob-01;
      top: calc(79px / $parentMobH * 100%);
    }
  }

  .move__hearth {
    top: 1px;
    transition: all 0.75s;
    transform: rotate(-5deg) scale(1);
    transition-delay: 2.1s;
  }

  .move__line {
    &-bg {
      left: 0;
      transition: left 0.25s ease-in;
      transition-delay: 1.75s;
    }

    &-inner {
      max-width: 65%;
      transition: max-width 0.25s ease-in;
      transition-delay: 2s;
    }
  }
}

.animated_first.animated_second {
  .move {
    top: 0;
    transition: all 1.5s ease-in-out;
    transform: translateX(-50%) translateY(-110%);

    @media (min-width: $breakpoint-lg) and (orientation: landscape) {
      transform: translateY(-110%);
    }
  }
}

@keyframes calendar-01 {
  from {
    top: 110%;
  }
  to {
    top: calc(442px / $parentH * 100%);
  }
}

@keyframes calendar-mob-01 {
  from {
    top: 50%;
  }
  to {
    top: calc(79px / $parentMobH * 100%);
  }
}
</style>>
