<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue';
import { storeToRefs } from 'pinia';
import { usePageStore } from '~/store/page/page';

const x = ref<number>(0);
const y = ref<number>(0);
const x1 = ref<number>(0);
const x2 = ref<number>(1);
const y2 = ref<number>(1);
const scale = ref<number>(1);
const isSmooth = ref<boolean>(false);
const re = ref<boolean>(false);
const speed = ref<number>(0.2);
const times = ref<number>(0);
const lim = ref<number>(10);
const count = ref<number>(0);
const isLoading = ref<boolean>(true);

const emit = defineEmits(['autoplayEnds']);

const pageStore = usePageStore();
const { isMobile, activeSection } = storeToRefs(pageStore);

const transform2 = computed(() => {
  return `transform: translate(${x.value * 0.2}%, ${y.value * 0.4}%)`;
});

const transform3 = computed(() => {
  return `transform: translate(${x.value * 0.3}%, ${y.value * 0.5}%)`;
});

const transform4 = computed(() => {
  return `transform: translate(${x.value * 0.4}%, ${y.value * 0.6}%)`;
});

const transform5 = computed(() => {
  return `transform: translate(${x.value * 0.5}%, ${y.value * 0.8}%)`;
});

const transformEyes = computed(() => {
  return `transform: translate(${x.value * 0.8}%, ${y.value * 2}%)`;
});

const transformPupils = computed(() => {
  return `transform: scale(${Math.min(Math.max(x2.value / scale.value, 0.6), 1.5)}, ${Math.min(
    Math.max(y2.value / scale.value, 0.6),
    1.5,
  )})`;
});

const eyesWatchedOblect = computed(() => {
  const elem = document.getElementById('watched-by-main-cat');
  return elem || false;
});

const start = () => {
  isSmooth.value = true;
  x.value = 0;
  y.value = 0;
  x1.value = 0;
  x2.value = 1;
  y2.value = 1;
  scale.value = 1;
  document.addEventListener('mousemove', mouseMove, { passive: true });
  document.addEventListener('mousemove', mouseEnter, { passive: true });
  document.addEventListener('touchmove', touchMove, { passive: true });
  document.addEventListener('touchstart', touchMove, { passive: true });
};

const autoplay = () => {
  if (re.value) {
    x.value -= speed.value;
  } else {
    x.value += speed.value;
  }

  if (times.value++ < lim.value) {
    requestAnimationFrame(autoplay);
  } else {
    setTimeout(() => {
      times.value = 0;
      re.value = !re.value;
      lim.value = 22;
      count.value++;
      if (count.value === 2) {
        lim.value = 10;
      }
      if (count.value === 3) {
        isSmooth.value = true;
        if (eyesWatchedOblect.value) {
          setTimeout(() => {
            const watchObject = setInterval(() => {
              if (eyesWatchedOblect.value instanceof HTMLElement) {
                const bounds = eyesWatchedOblect.value.getBoundingClientRect();
                catMove(bounds.x, bounds.y);
              }
            }, 50);
            setTimeout(() => {
              isSmooth.value = false;
            }, 1000);
            setTimeout(() => {
              clearInterval(watchObject);
              start();
              emit('autoplayEnds');
            }, 4000);
          }, 400);
        } else {
          start();
          emit('autoplayEnds');
        }
      } else {
        requestAnimationFrame(autoplay);
      }
    }, 300);
  }
};

const mouseEnter = () => {
  document.removeEventListener('mousemove', mouseEnter);
  if (!isMobile) {
    setTimeout(() => {
      isSmooth.value = false;
    }, 1000);
  }
};

const touchMove = (e: any) => {
  catMove(e.touches[0].pageX, e.touches[0].pageY, e.scale);
};

const mouseMove = (e: any) => {
  catMove(e.pageX, e.pageY, e.scale);
};

const catMove = (xCor: number, yCor: number, scaleNum?: number): void => {
  if (Number(activeSection) > 0) return;
  const w = window.innerWidth / 2;
  const h = window.innerHeight / 2;
  scale.value = scaleNum && scaleNum > 1 ? scaleNum * 50 : 1;
  x.value = (xCor - w) / (w / 2);
  y.value = Math.max(-1.5, Math.min((yCor - window.screenY - h) / (w / 2), 2));
  x2.value = Math.abs((xCor - w) / w) + 0.4;
  y2.value = Math.min(1, Math.abs((xCor - w) / w) + 0.9);
};

onMounted(() => {
  setTimeout(() => {
    isLoading.value = false;
  }, 700);
  setTimeout(() => {
    requestAnimationFrame(autoplay);
  }, 2000);
});

onUnmounted(() => {
  document.removeEventListener('mousemove', mouseMove);
  document.removeEventListener('mousemove', mouseEnter);
  document.removeEventListener('touchmove', touchMove);
  document.removeEventListener('touchstart', touchMove);
});
</script>

<template>
  <div :class='{ is_loading: isLoading }' class='main-cat'>
    <div class='main-cat__gradient'></div>
    <div
      :style='transform2'
      :class='{ is_smooth: isSmooth }'
      class='main-cat__layer'
    >
      <div class='main-cat__layer main-cat__layer--1'></div>
      <div
        :style='transform2'
        :class='{ is_smooth: isSmooth }'
        class='main-cat__layer main-cat__layer--2'
      ></div>
      <div
        :style='transform3'
        :class='{ is_smooth: isSmooth }'
        class='main-cat__layer main-cat__layer--3'
      ></div>
      <div
        :style='transformEyes'
        :class='{ is_smooth: isSmooth }'
        class='main-cat__layer main-cat__layer--eyes'
      ></div>
      <div
        :style='transformEyes'
        :class='{ is_smooth: isSmooth }'
        class='main-cat__layer'
      >
        <div class='main-cat__pupil-layout main-cat__pupil-layout--right'>
          <div :style='transformPupils' class='main-cat__pupil'></div>
        </div>
        <div class='main-cat__pupil-layout main-cat__pupil-layout--left'>
          <div :style='transformPupils' class='main-cat__pupil'></div>
        </div>
      </div>
      <div
        :style='transform3'
        :class='{ is_smooth: isSmooth }'
        class='main-cat__layer main-cat__layer--specks'
      ></div>
      <div
        :style='transform4'
        :class='{ is_smooth: isSmooth }'
        class='main-cat__layer main-cat__layer--4'
      ></div>
      <div
        :style='transform5'
        :class='{ is_smooth: isSmooth }'
        class='main-cat__layer main-cat__layer--5'
      ></div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.main-cat {
  position: relative;
  transition: transform 1s 0.5s;
  overflow: hidden;
  width: 100%;
  aspect-ratio: 1;

  @media (min-width: $breakpoint-lg) and (orientation: landscape) {
    width: auto;
    height: min(800px, 70vh);
    aspect-ratio: 10/8;
    transform: none;
  }

  @media (min-width: $breakpoint-xxl) and (orientation: landscape) {
    height: min(850px, 74vh);
  }

  &.is_loading {
    transform: translateY(150%);

    @media (min-width: $breakpoint-lg) and (orientation: landscape) {
      transform: translateY(100%);
    }
  }
}

.main-cat__gradient {
  position: absolute;
  width: 100vw;
  height: 100%;
  z-index: 1;
  background: linear-gradient(to top, var(--color-primary) 0%, rgba(0, 0, 0, 0) 10%, rgba(0, 0, 0, 0) 90%);
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;

  @media (min-width: $breakpoint-lg) and (orientation: landscape) {
    display: none;
  }
}

.main-cat__layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: 100%;
  background-repeat: no-repeat;

  &.is_smooth {
    transition: transform 0.3s;
  }

  &--1 {
    margin-top: 30%;
    background-image: url('/assets/images/main-cat/main-cat-mobile-1.webp');

    @media (min-width: $breakpoint-lg) and (orientation: landscape) {
      background-image: url('/assets/images/main-cat/main-cat-1.webp');
      margin-top: 0;
    }
  }

  &--2 {
    background-image: url('/assets/images/main-cat/main-cat-mobile-2.webp');

    @media (min-width: $breakpoint-lg) and (orientation: landscape) {
      background-image: url('/assets/images/main-cat/main-cat-2.webp');
    }
  }

  &--3 {
    background-image: url('/assets/images/main-cat/main-cat-mobile-3.webp');

    @media (min-width: $breakpoint-lg) and (orientation: landscape) {
      background-image: url('/assets/images/main-cat/main-cat-3.webp');
    }
  }

  &--4 {
    background-image: url('/assets/images/main-cat/main-cat-mobile-4.webp');

    @media (min-width: $breakpoint-lg) and (orientation: landscape) {
      background-image: url('/assets/images/main-cat/main-cat-4.webp');
    }
  }

  &--5 {
    background-image: url('/assets/images/main-cat/main-cat-mobile-5.webp');

    @media (min-width: $breakpoint-lg) and (orientation: landscape) {
      background-image: url('/assets/images/main-cat/main-cat-5.webp');
    }
  }

  &--eyes {
    background-image: url('/assets/images/main-cat/main-cat-mobile-eyes.webp');

    @media (min-width: $breakpoint-lg) and (orientation: landscape) {
      background-image: url('/assets/images/main-cat/main-cat-eyes.webp');
    }
  }

  &--specks {
    background-image: url('/assets/images/main-cat/main-cat-specks.webp');
  }
}

.main-cat__pupil-layout {
  position: absolute;
  left: 50%;
  width: 5.5%;
  aspect-ratio: 24/38;

  @media (min-width: $breakpoint-lg) and (orientation: landscape) {
    width: 4.5%;
  }

  &--right {
    transform: rotate(-8deg);
    top: 35%;
    margin-left: 11.5%;

    @media (min-width: $breakpoint-lg) and (orientation: landscape) {
      top: 46.5%;
      margin-left: 11.6%;
    }
  }

  &--left {
    transform: rotate(4deg);
    top: 37.3%;
    margin-left: -15.5%;

    @media (min-width: $breakpoint-lg) and (orientation: landscape) {
      top: 48.6%;
      margin-left: -14.4%;
    }
  }
}

.main-cat__pupil {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-image: url('/assets/images/main-cat/main-cat-pupil.webp');
  transition: transform 0.6s linear;
}
</style>
