import { defineStore } from 'pinia';
import type { Main } from './types';
import type { Shop } from '~/store/shops/types';
import type { Question } from '~/store/faq/types';

export const useMainStore = defineStore('main-store', {
  state: (): Main => ({
    FAQList: [],
    shops: [],
    isMobile: false,
    animationPlayHero: false,
  }),
  actions: {
    setShops(data: Shop[]) {
      this.shops = data;
    },
    setFaq(data: Question[]) {
      this.FAQList = data;
    },
  },
});
